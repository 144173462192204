@font-face {
  font-family: "SF Display";
  font-weight: 400;
  src: url("./fonts/SF-UI-Display-Regular.otf") format("opentype");
}

@font-face {
  font-family: "SF Display";
  font-weight: 500;
  src: url("./fonts/SF-UI-Display-Medium.otf") format("opentype");
}

@font-face {
  font-family: "SF Display";
  font-weight: 700;
  src: url("./fonts/SF-UI-Display-Bold.otf") format("opentype");
}
